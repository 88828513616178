<template>
  <div>
    <div class="ft20 cl-black cl-main ftw600">操作日志</div>

    <div class="mt20">
      <div class="form-search-box ">
        <a-form layout="inline">

          <a-form-model-item label="操作时间" >
            <a-range-picker
                :show-time="{ format: 'HH:mm' }"
                format="YYYY-MM-DD"
                :placeholder="['操作开始时间', '操作结束时间']"
                @ok="onOkAct"
                @change="changeAct"
                v-model="search.times"
            />
          </a-form-model-item>

          <a-form-item>
            <a-button @click="searchAct" type="primary">查询</a-button>
            <a-button @click="cancelAct" class="ml10">取消</a-button>
          </a-form-item>
        </a-form>
      </div>
    </div>

    <div class="mt20">
      <div class="bg-w">
        <div class="pd20">
          <div class="wxb-table-gray">
            <a-table rowKey="id" :columns="columns" :pagination="pagination" :data-source="datas" @change="handleTableChange" :loading="loading"></a-table>
          </div>
        </div>
      </div>
    </div>

  </div>

</template>

<script>
import {listMixin} from '../../common/mixin/list.js';
export default {
  mixins: [listMixin],
  data() {
    return {
      loading: false,
      editloading:false,
      miniqrcode:'',
      confirmLoading:false,
      pagination: {
        current: 1,
        pageSize: 10, //每页中显示10条数据
        total: 0,
      },
      search: {
        times: [],
      },
      level:[],
      columns: [
        {title: '编号',dataIndex: 'id',align: 'center',width:100},
        {title: '操作者',dataIndex: 'user_name',align: 'center',width:200},
        {title: '路径',dataIndex: 'paths',align: 'left',ellipsis: true},
        {title: '节点',dataIndex: 'controller',align: 'left',ellipsis: true},
        {title: '参数',dataIndex: 'params',align: 'left',ellipsis: true},
        {title: '操作时间',dataIndex: 'time_format',align: 'left',ellipsis: true},
      ],
      datas: [],
      member:{},
    }
  },
  methods: {
    cancelAct(){
      this.search.times=[];
      this.getLists();
    },
    getLists(){
      if(this.loading==true) return;
      this.loading=true;
      this.$http.api('admin/dologs',{
        times:this.search.times,
        limit:this.pagination.pageSize,
        page:this.pagination.current,
      }).then(res=>{
        this.pagination.total=res.total;
        this.pagination.showTotal=(total)=>"共"+res.total+"条";
        this.datas=res.list;
        this.level=res.level;
        this.loading=false;
      }).catch(res=>{
        this.loading=false;
      })
    },
    onOkAct(value) {
      console.log('onOkAct: ', value);
    },
    changeAct(date,dateString){
      this.search.times = dateString;
    },
    handleTableChange(pagination, filters, sorter) {
      this.pagination.current = pagination.current;
      this.getLists();
    }
  }
}
</script>

<style>
.index-face {
  width: 44px;
  height: 44px;
  border-radius: 44px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.04);
  border: 2px solid #FFFFFF;
}

.member-index-action {
  width: 32px;
  height: 32px;
  border-radius: 32px;
  background: #FFFFFF;
  border: 1px solid #EBEDF5;
  text-align: center;
  line-height: 32px;
}
</style>
